$danger:#FF304F;
$secondary:#CACACA;
$primary:#015668;
$success:#06648C;
$info:#0F81C7;
$warning:rgb(8, 188, 194);
$light:#ececec;
$dark:#222222;
$spacer:1rem;
$border-width: 1px;
$btn-border-radius:0;
$list-group-border-color: rgba($dark, .1) !default;

$headings-font-family:'Playfair Display';
$font-weight-light:           300;
$font-weight-normal:          300;
$font-weight-bold:            500;
$font-family-base: 'Roboto', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";