@import '../../variables.scss';

.recipe-badge .badge {
  font-size: 16px;
  padding: 8px 8px 8px 10px;
  margin-top: 2px;
}

.ingredient--cant-make {
  position: relative;
  .ingredient-add-button {
    opacity: 0;
    display: none;
    transition: all 0.125s ease-in-out .5s;
    z-index: 100;
    color: $success;
  }
  &:hover .ingredient-add-button {
    opacity: 1;
    display: inline-block;
    transition: all .125s ease-in-out 0s;
  }
}

.ingredient-add-button:hover {
  color: $warning;
}

.in-shopping-list {
  opacity: 0;
  color: #adb5bd;
  transition: opacity .125s ease-in-out 0s;
  .ingredient--cant-make:hover & {
    transition: opacity .125s ease-in-out 0s;
    opacity: 1;
  }
}

.ingredient__label {
  margin: 0 5px;
}

.ingredient__unit {
  margin: 0 3px;
}

.recipe-ingredients {
  list-style: none;
  margin: 0;
  padding: 0;
}

.ingredient__label {
  font-weight: $font-weight-bold;
}

.ingredient__amount-wrap {
  width: 70px;
  display: inline-block;
  text-align: right;
}

.cocktail-name {
  font-size: 2.25em;
}

.cocktail-header {
  border-bottom: 1px solid lightgray;
}

.cocktail-edit,
.cocktail-remove {
  transition: all 0.125s ease-in-out;
}

.cocktail-remove {
  color: $secondary;
  &:hover {
    color: $danger;
  }
}

.cocktail-edit {
  color: $secondary;
  &:hover {
    color: $warning;
  }
}

.cocktail-utils {
  opacity: 0;
  transition: opacity .125s ease-in-out;
  position: relative;
  top: -10%;
  .cocktail-item:hover & {
    opacity: 1;
  }
}

.bookmark-recipe {
  color: $warning;
  font-size: 24px;
  margin-top: 6px;
  position: relative;
  transition: all .125s ease-in-out;
  &:hover {
    color: darken($warning, 15%);
    transform: scale(1.125);
  }
}

.cocktail-item.cant-make {
  color: darken($secondary, 25%);
}

.cocktail-item.can-make {
  border-bottom: 4px solid $warning;
}

.cocktail-item {
  transition: all .125s ease-in-out;
  &:hover {
    box-shadow: 0 0 15px rgba($secondary, 0.45);
    transform: scale(1.02);
  }
}