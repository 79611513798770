@import "../../variables.scss";

.shopping-badge {
  font-size: .65em;
  position:relative;
  top: -2px;
}

@media (min-width: 900px) {
  .shopping-list-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1em;
  }
}

.shopping-bought {
  color: $warning;
  &:hover {
    color: darken($warning, 10%);
  }
}

.shopping-remove {
  color: $secondary;
  &:hover {
    color: $danger;
  }
}

.shopping-item {
  transition: all .125s ease-in-out 0.125s;
  &:hover {
    box-shadow: 0 0 15px rgba($secondary, 0.45);
    transform: scale(1.03);
    transition: all .125s ease-in-out 0s;
  }
}